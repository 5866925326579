<template>
  <div>
    <SettingsTitle title="Scrolling behavior "/>
    <SettingsLines :items="items" @changeState="changeState"/>
  </div>
</template>

<script>
  import SettingsLines from '@/components/BucketsBlocks/SettingsLines'
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    components: { SettingsLines, SettingsTitle },
    data(){
      return{
        items: [
          {
            id: 1,
            title: 'Close the Player in Player (PiP) when the user scrolls',
            state: false,
            state_name: 'close_pip_scroll'
          },
          {
            id: 2,
            title: 'Close the video details when the user scrolls',
            state: false,
            state_name: 'close_details_scroll'
          }
        ]
      }
    },
    computed:{
      ...mapGetters({
        close_pip_scroll: 'settings/close_pip_scroll',
        close_details_scroll: 'settings/close_details_scroll'
      }),
    },
    methods: {
      ...mapMutations({
        set_close_pip_scroll: 'settings/set_close_pip_scroll',
        set_close_details_scroll: 'settings/set_close_details_scroll'
      }),
      changeState(arg){
        this.items = this.items.map(elem =>{
          if(elem.id == arg.id) {
            elem.state = arg.event
            return elem
          }else{
            return elem
          }
        })
        let item = this.items.find(elem => elem.id == arg.id)
        this[`set_${item.state_name}`](arg.event)

        this.$localStorage.set('user_metadata', JSON.stringify({
          close_pip_scroll: this.close_pip_scroll,
          close_details_scroll: this.close_details_scroll
        }))
      }
    },
    created(){
      this.items.forEach(elem => elem.state = this[elem.state_name])
    }
  }
</script>

<style lang="scss" scoped>

</style>